import { useAuth } from "@contexts/auth_context";
import { useParams } from 'react-router-dom';
import { useState } from "react";
import { Heading, Text, Container, Flex, Divider, Table, Thead, Tbody, Tr, Th, Td, useTheme, Stat, StatNumber, StatLabel } from "@chakra-ui/react";
import { MdMyLocation } from "react-icons/md";
import { useTimezone } from "@contexts/timezone_context";
import styles from "./styles.module.scss";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import {ErrorPage} from "@/components/ErrorPage";
import { ShiftMap } from "@/components/ShiftMap";
import { ShiftStatus } from "@/components/ShiftStatus";
import { LocationStatus } from "@/components/LocationStatus";
import useSWR, { mutate } from "swr";
import { BiEdit } from "react-icons/bi";
import { PunchEditModal } from "@/components/PunchEditModal";
import { formatDuration } from "@/utils/formatting";

const formatPunchType = (punchType: string): string => {
  switch (punchType) {
    case "clock_in":
      return "Clock In";
    case "clock_out":
      return "Clock Out";
    case "break_start":
      return "Break Start";
    case "break_end":
      return "Break End";
    case "meal_start":
      return "Meal Start";
    case "meal_end":
      return "Meal End";
    case "pre_check":
      return "Geolocation Check"
    default:
      return punchType;
  }
};

const getPunchLocationsForMap = (punches) => {
  return punches
    .filter(punch => punch.latitude !== null && punch.longitude !== null)
    .map(punch => ({
      punch_id: punch.id,
      label: formatPunchType(punch.punchType),
      longitude: punch.longitude,
      latitude: punch.latitude,
    }))
}

const ShiftPage = () => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const { shift_id } = useParams();
  const authContext = useAuth();
  const theme = useTheme();
  const timezoneContext = useTimezone();
  const [centeredLocation, setCenteredLocation] = useState(null);
  const allowPunchCorrection = authContext.state.features?.punchCorrection || false

  const getPunchTimestamp = (punch) => {
    const punchInTimestamp = new Date(punch.punchInTimestamp);
    const createdAt = new Date(punch.createdAt);
    let result = timezoneContext.formatDate(punchInTimestamp, "MMM dd, h:mm aaa");
    if (punch.creatorId && (punch.userId !== punch.creatorId)) {
      result += " (corrected on " + timezoneContext.formatDate(createdAt, "MMM dd") + ")";
    }
    if (punch.discardedAt) {
      result += " (removed on " + timezoneContext.formatDate(new Date(punch.discardedAt), "MMM dd") + ")";
    }
    return result;
  }

  const renderPunch = (punch, theme, setCenteredLocation) => {
    const textColor = punch.discardedAt ? theme.colors.blackAlpha["400"] : undefined;
    const iconColor = punch.discardedAt ? theme.colors.blackAlpha["300"] : theme.colors.blackAlpha["600"];

    let punchGeoTemplate: JSX.Element = <div>N/A</div>;
    if (!!punch.distance) {
      punchGeoTemplate = <Flex alignItems="center" title={"Location Accuracy: ±" + punch.accuracy + "m"}>
        <MdMyLocation
          size={16}
          style={{marginRight: 6}}
          color={iconColor}
          cursor="pointer"
          onClick={() => setCenteredLocation(punch)}
        />
        <Text>{punch.distance + "m"}</Text>
      </Flex>;
    } else if (punch.punchSource === "ivr") {
      punchGeoTemplate = <Text>Punch logged via IVR.</Text>;
    }

    return (
      <Tr
        key={punch.id}
        textColor={textColor}
      >
        <Td>{getPunchTimestamp(punch)}</Td>
        <Td>{formatPunchType(punch.punchType)}</Td>
        <Td>
          {punchGeoTemplate}
        </Td>
      </Tr>
    );
  };

  const { data, error, isLoading } = useSWR(
    shift_id,
    shift_id => {
      return authContext.authenticatedFetch(`${import.meta.env.VITE_API_SERVER}/shifts/get_shift_by_id.json?shift_id=${shift_id}`, {
        method: "get",
        headers: {
          "Accept": "application/json",
          "Content-Type": "application/json",
        },
      })
      .then(response => response.json())
    }
  );

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error}/>;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error}/>;
  }

  const shift = data.shift;
  const { location, punches, employee } = shift;
  const durationWithoutBreakInSeconds = shift.duration ? shift.duration - ((shift.lunchMinutes || 0) * 60) - ((shift.breakMinutes || 0) * 60) : null;
  return (
    <Container maxW="960px" className={styles.container}>
      <Heading textTransform='uppercase' className={styles.header}>
        <Text>{employee.name}</Text>
        <Flex alignItems="center">
          <ShiftStatus eventStatus={shift.status} />
          <LocationStatus locationStatus={shift.locationStatus} />
        </Flex>
      </Heading>

      <Divider />

      <div className={styles.sectionContainer}>
        <Text className={styles.sectionHeader}>Schedule</Text>
        <Flex>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>Start</StatLabel>
            <StatNumber className={styles.content} fontSize='md'>{timezoneContext.formatDate(new Date(shift.calendarStartDatetime), "MMM dd, h:mm aaa")}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>End</StatLabel>
            <StatNumber className={styles.content} fontSize='md'>{timezoneContext.formatDate(new Date(shift.calendarEndDatetime), "MMM dd, h:mm aaa")}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>Building:</StatLabel>
            <StatNumber className={styles.content} fontSize="md">
              {location.name}
            </StatNumber>
          </Stat>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>Address</StatLabel>
            <a href={location.mapUrl} target="_blank" rel="noopener noreferrer">
              <StatNumber className={styles.content} fontSize="md">
                {location.address}
              </StatNumber>
            </a>
          </Stat>
        </Flex>
      </div>

      <Divider />

      <div className={styles.sectionContainer}>
        <Text className={styles.sectionHeader}>Summary</Text>
        <Flex>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>Clocked In At</StatLabel>
            <StatNumber className={styles.content} fontSize='md'>{shift.clockInTimestamp ? timezoneContext.formatDate(new Date(shift.clockInTimestamp), "MMM dd, h:mm aaa") : "-" }</StatNumber>
          </Stat>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>Clocked Out At</StatLabel>
            <StatNumber className={styles.content} fontSize='md'>{shift.clockOutTimestamp ? timezoneContext.formatDate(new Date(shift.clockOutTimestamp), "MMM dd, h:mm aaa") : "-"}</StatNumber>
          </Stat>
          <Stat>
            <StatLabel className={styles.label} fontSize='md'>Duration</StatLabel>
            <StatNumber className={styles.content} fontSize='md'>
              {durationWithoutBreakInSeconds ? formatDuration(durationWithoutBreakInSeconds) : "-"}</StatNumber>
          </Stat>
          <Stat visibility={shift.lunchMinutes ? 'visible' : 'hidden'}>
            <StatLabel className={styles.label} fontSize='md'>Lunch break</StatLabel>
            <StatNumber className={styles.content} fontSize='md'>{shift.lunchMinutes} minutes</StatNumber>
          </Stat>
        </Flex>
      </div>

      <Divider />
      <div className={styles.sectionContainer}>
        <div className={styles.sectionHeader} style={{ display: 'flex', alignItems: 'center'}}>
        <Text>
          Details
        </Text>
        { allowPunchCorrection ?
          <BiEdit
              style={{ cursor: 'pointer', marginLeft: '10px' }}
              onClick={() => setIsEditModalOpen(true)}
            /> : null}
        </div>
        <div style={{ height: "450px", width: "100hv" }} mt="4">
          <ShiftMap
            shiftLocation={{...location, label: location.name}}
            punchLocations={getPunchLocationsForMap(punches)}
            center={centeredLocation}
            resetCenter={()=>setCenteredLocation(null)} />
        </div>

        <div className={styles.punchCard}>
        <Table>
          <Thead>
            <Tr>
              <Th>Timestamp</Th>
              <Th>Type</Th>
              <Th>Distance from Building</Th>
            </Tr>
          </Thead>
          <Tbody>
            {punches.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)).map((punch) => (
              renderPunch(punch, theme, setCenteredLocation)
            ))}
          </Tbody>
        </Table>
        </div>
      </div>
      <PunchEditModal
        isOpen={isEditModalOpen}
        onClose={() => setIsEditModalOpen(false)}
        shift={shift}
        onSave={() => {
          setIsEditModalOpen(false);
          mutate(shift_id);
        }}
      />
    </Container>
  );
};

export default ShiftPage;
