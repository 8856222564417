import { useAuth } from "@contexts/auth_context";
import { useTheme, Flex, Table, Thead, Tr, Th, Tbody, Td, Spinner } from "@chakra-ui/react";
import useSWR from 'swr';
import styles from './styles.module.scss';
import { ErrorView } from "../ErrorView";

const RealTimeLaborTable = ({ }) => {
  const authContext = useAuth();

  const { data: overtimeSupervisorBreakdownData, error: overtimeSupervisorBreakdownError } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/real_time_labor/overtime/supervisor_breakdown`,
    async (url) => {
      const response = await authContext.authenticatedFetch(url);
      return response.json();
    }
  );

  const { data: budgetActualSupervisorBreakdownData, error: budgetActualSupervisorBreakdownError } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/real_time_labor/budget_actual/supervisor_breakdown`,
    async (url) => {
      const response = await authContext.authenticatedFetch(url);
      return response.json();
    }
  );

  const isLoading = (!overtimeSupervisorBreakdownData && !overtimeSupervisorBreakdownError) ||
                    (!budgetActualSupervisorBreakdownData && !budgetActualSupervisorBreakdownError);

  const mergeData = () => {
    const mergedData = {};
    const users = {...overtimeSupervisorBreakdownData?.users, ...budgetActualSupervisorBreakdownData?.users};

    [...(overtimeSupervisorBreakdownData?.supervisorBreakdown || []),
     ...(budgetActualSupervisorBreakdownData?.supervisorBreakdown || [])]
    .forEach(breakdown => {
      if (!mergedData[breakdown.supervisorId]) {
        mergedData[breakdown.supervisorId] = {
          supervisorId: breakdown.supervisorId,
          overtimeHours: 0,
          budgetHours: 0,
          actualHours: 0
        };
      }
      mergedData[breakdown.supervisorId].overtimeHours = breakdown.overtimeHours ?? mergedData[breakdown.supervisorId].overtimeHours;
      mergedData[breakdown.supervisorId].budgetHours = breakdown.budgetHours ?? mergedData[breakdown.supervisorId].budgetHours;
      mergedData[breakdown.supervisorId].actualHours = breakdown.actualHours ?? mergedData[breakdown.supervisorId].actualHours;
    });

    return { supervisorBreakdown: Object.values(mergedData), users };
  };

  const { supervisorBreakdown, users } = mergeData();

  if (overtimeSupervisorBreakdownError || budgetActualSupervisorBreakdownError) {
    return <ErrorView message="Failed to load overtime and budget/actual data" />;
  }
  if (isLoading) {
    return <Flex justifyContent="center" alignItems="center" height="100%"><Spinner size="xl" color="purple.500" /></Flex>;
  }

  return (
    <Flex
      my={6}
      flex={1}
      flexDirection="column"
      overflowY="hidden"
    >
      <Flex className={styles.tableHeaderContainer}>
        <Table variant="simple" colorScheme="gray">
          <Thead position="sticky" top={0} bg="white">
            <Tr>
              <Th width="40%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={6} >Supervisor</Th>
              <Th width="30%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Budget/Actual</Th>
              <Th width="30%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Overtime</Th>
            </Tr>
          </Thead>
        </Table>
      </Flex>
      <Flex
        overflowY="scroll"
      >
        <Table variant="simple" colorScheme="gray">
          <Tbody>
            {supervisorBreakdown.map((breakdown) => (
              <Tr width="100%"
                key={breakdown.supervisorId}
                _hover={{ bg: 'gray.50' }}
              >
                <Td width="40%" pl={6} >{users[breakdown.supervisorId]?.name || 'Unknown'}</Td>
                <Td width="30%" pl={0} >{`${breakdown.budgetHours.toFixed(0)} / ${breakdown.actualHours.toFixed(0)}`}</Td>
                <Td width="30%" pl={0} >{breakdown.overtimeHours.toFixed(0)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      </Flex>
    </Flex>
  );
};

export default RealTimeLaborTable;
