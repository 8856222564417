import { useAuth } from "@contexts/auth_context";
import { Heading, Text, Flex, Table, Thead, Tbody, Tr, Th, Td, useTheme, Button, Box, Select, Icon } from "@chakra-ui/react";
import { FullScreenSpinner } from "@/components/FullScreenSpinner";
import {ErrorPage} from "@/components/ErrorPage";
import useSWR from "swr";
import { EventFilter } from "@/components/EventFilter";
import { useState, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import styles from "./styles.module.scss";
import { domainUrl } from "@/utils/fetch_utils";
import { ICreateIssueParams, IIssue, IssueStage, StageTagMappings, convertIssueFromServer, filterIssues } from "@/types/issue";
import { IssueTag } from "@/components/IssueTag";
import { FaClock } from "react-icons/fa6";
import { IssueEditModal } from "@/components/IssueEditModal";
import { useTimezone } from "@contexts/timezone_context";

const IssueDashboard: React.FC = () => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();

  // Filtering
  const [resetKey, setResetKey] = useState<boolean>(false);
  const [isBuildingFilterLoading, setIsBuildingFilterLoading] = useState<boolean>(true);
  const [isAssigneeFilterLoading, setIsAssigneeFilterLoading] = useState<boolean>(true);
  const [isStageFilterLoading, setIsStageFilterLoading] = useState<boolean>(true);
  const [buildingFilteredIssueIds, setBuildingFilteredIssueIds] = useState<Set<number>>(new Set());
  const [assigneeFilteredIssueIds, setAssigneeFilteredIssueIds] = useState<Set<number>>(new Set());
  const [stageFilteredIssueIds, setStageFilteredIssueIds] = useState<Set<number>>(new Set());
  const [issues, setIssues] = useState<Array<IIssue>>([]);
  const [locations, setLocations] = useState<Array<any>>([]);
  const [users, setUsers] = useState<Array<any>>([]);
  const [suggestedUsersByLocationId, setSuggestedUsersByLocationId] = useState<{ [key: number]: Array<any> }>({});

  const [editIssueModalOpen, setEditIssueModalOpen] = useState<boolean>(false);
  const [searchParams, setSearchParams] = useSearchParams();

  // Sorting
  const [sorting, setSorting] = useState<keyof sortingOptions>("due_date");
  const sortingOptions = {
    "due_date": {
      label: "Due Date",
      sortFunction: (a: IIssue, b: IIssue) => a.dueDate.getTime() - b.dueDate.getTime()
    },
    "building_name": {
      label: "Building Name",
      sortFunction: (a: IIssue, b: IIssue) => a.location.name.localeCompare(b.location.name)
    },
  }

  const issueIdSetsFromOtherFilters = (issueIdSetForCurrentFilter: Set<number>) => {
    return [buildingFilteredIssueIds, assigneeFilteredIssueIds, stageFilteredIssueIds].filter((issueIdSet) => {
      return issueIdSet != issueIdSetForCurrentFilter;
    });
  }

  const rerenderFilters = () => {
    // hide all issue before rerendering to avoid flickering.
    setBuildingFilteredIssueIds(new Set());
    setAssigneeFilteredIssueIds(new Set());
    setStageFilteredIssueIds(new Set());
    setResetKey(!resetKey);
  }

  const setFilterLoading = () => {
    setIsBuildingFilterLoading(true);
    setIsAssigneeFilterLoading(true);
    setIsStageFilterLoading(true);
  }

  const { data, error, isLoading } = useSWR(
    `${domainUrl}/issues`,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then((response) => response.json())
    }
  );


  const { data: locationsData, error: locationsError, isLoading: locationsIsLoading } = useSWR(
    `${domainUrl}/locations/user_suggestion_by_location`,
    url => {
      return authContext.authenticatedFetch(url, {
        method: "get",
      })
      .then((response) => response.json())
    }
  );

  const createIssue = async (issueParams: ICreateIssueParams) => {
    const formData = new FormData();

    try {
      (issueParams?.attachmentsToUpload || []).forEach((photo, index) => {
        formData.append(`attachments[${index}]`, photo);
      });

      formData.append("currentStage", IssueStage.OPEN);
      formData.append("locationId", issueParams.locationId);
      formData.append("assigneeId", issueParams.assigneeId);
      formData.append("title", issueParams.title);
      formData.append("dueDate", issueParams.dueDate.toJSON());
      if (issueParams.notesForCleaner) formData.append("notesForCleaner", issueParams.notesForCleaner);

      // TODO(connor): REASSIGN TASK (this can be figured out with offline flow & interim states of submission of issues)
      const response = await authContext.authenticatedFetch(`${domainUrl}/issues`, {
        method: "POST",
        body: formData,
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });

      const statusCode = await response.status;
      const json = await response.json();

      if (json.error) {
        return json.error;
      }
      if (statusCode !== 200) {
        return "Unable to resolve issue. Please check your network connection and try again.";
      }

      const convertedIssue = convertIssueFromServer(json.issue, json.locations, json.users);
      setIssues([...issues, convertedIssue]);

      return convertedIssue;
    } catch (error) {
      console.error(error);
      return -1;
    }
  };

  const filterIssues = (currentIssues: Array<IIssue>) => {
    return currentIssues.filter((issue: IIssue) => {
      return buildingFilteredIssueIds.has(issue.id)
      && assigneeFilteredIssueIds.has(issue.id) && stageFilteredIssueIds.has(issue.id);
    }).sort ((a, b) => {
      return sortingOptions[sorting].sortFunction(a, b);
    })
  }

  useEffect(() => {
    if (data) {
      setIssues(data.issues.map((issue: any) => convertIssueFromServer(issue, data.locations, data.users)));
    } else {
      setIssues([]);
    }
  }, [data]);

  const filteredIssues = filterIssues(issues);

  useEffect(() => {
    if (locationsData) {
      setLocations(Object.values(locationsData.locations).sort((a, b) => a.name.localeCompare(b.name)));
      setUsers(Object.keys(locationsData?.users).map((userId) => ({ ...(locationsData?.users[userId]), id: userId })));

      // Structure: { locationId: [User1, User2] }
      const transformedUserSuggestions = Object.keys(locationsData.userSuggestions).reduce((result, key) => {
        const userIds = locationsData.userSuggestions[key]
        result[key] = userIds.map((userId: string | number) => ({ ...(locationsData?.users[userId]), id: userId }));
        return result;
      }, {});
      setSuggestedUsersByLocationId(transformedUserSuggestions);
    } else {
      setLocations([]);
      setUsers([]);
      setSuggestedUsersByLocationId({});
    }
  }, locationsData)

  useEffect(() => {
    // useEffect has to be above any early returns
    // https://github.com/vercel/swr/discussions/1815
    if (data && data.issues) {
      // This applies the filters to the just loaded issues.
      // This will also eventually set the filters to be loaded.
      rerenderFilters();
    } else {
      // Set filters to be loading when data is reset to null.
      setFilterLoading();
    }
  }, [data, issues]);

  if (isLoading) {
    return <FullScreenSpinner />;
  }

  // handle network errors
  if (error) {
    return <ErrorPage error={error}/>;
  }

  // handle API specific errors
  if (data.error) {
    return <ErrorPage errorMessage={data.error}/>;
  }

  const onRowClick = (issueId: number) => {
    window.open(`/issues/${issueId}`, '_blank');
  };

  const renderDueDate = (issue: IIssue) => {
    const currentDate = new Date();
    let color = null;

    if (issue?.dueDate) {
      if (issue.currentStage !== IssueStage.CLOSED) {
        if (timezoneContext.isSameDay(issue.dueDate, currentDate)) {
          color = "secondary.orange";
        } else if (issue.dueDate < currentDate) {
          color = "secondary.red";
        }
      }

      return (
        <Flex flexDirection="row" alignItems="center">
          { color ? <Icon style={{ height: 16, width: 16, marginRight: 4 }} as={FaClock} color={color} /> : null }
          <Text>{timezoneContext.formatDate(issue?.dueDate, "MMM d, yyyy")}</Text>
        </Flex>
      );
    }

    return null;
  }

  return (
    <Flex
      flexDirection={"column"}
      height="100%"
    >
      <Heading
        as="h1"
        size="lg"
        marginLeft={6}
        marginRight={6}
        paddingTop={6}
        marginBottom={0}
      >
        <Flex flexDirection="row" alignContent="center" justifyContent="space-between">
          <Text>Issue Tracking</Text>
          <Button onClick={() => setEditIssueModalOpen(true)}>+ Add Issue</Button>
        </Flex>
      </Heading>
      <Box
        borderRadius="3xl"
        flex={1}
        overflowY="hidden"
        backgroundColor="white"
        border={`1px solid ${theme.colors.gray[200]}`}
        display="flex"
        flexDirection="column"
        margin={6}
        height="100%"
      >
        <Flex
          justifyContent="space-between"
          mx={6}
          mt={6}
        >
          <Flex
            flexDirection={"column"}
            alignItems={"flex-start"}
          >
            <Text>Filter by:</Text>
            <Flex
              alignItems={"center"}
            >
              <Box>
                <EventFilter
                  key={"BuildingFilter" + resetKey}
                  isLoading={isBuildingFilterLoading}
                  events={issues}
                  eventIdSetsFromOtherFilters={issueIdSetsFromOtherFilters(buildingFilteredIssueIds)}
                  getFilterKey={(issue: IIssue) => issue.location.name}
                  getEventId={(issue: IIssue) => issue.id}
                  convertFilterKeyToLabel={(filterKey: string) => filterKey}
                  updateEvent={(issueIds: Set<number>) => {
                    setBuildingFilteredIssueIds(issueIds);
                    setIsBuildingFilterLoading(false);
                  }}
                  filterName="Building"
                />
              </Box>
              <Box mx={6}>
                <EventFilter
                  key={"StageFilter" + resetKey}
                  isLoading={isStageFilterLoading}
                  events={issues}
                  eventIdSetsFromOtherFilters={issueIdSetsFromOtherFilters(stageFilteredIssueIds)}
                  getFilterKey={(issue: IIssue) => StageTagMappings[issue.currentStage].label}
                  getEventId={(issue: IIssue) => issue.id}
                  convertFilterKeyToLabel={(filterKey: string) => filterKey}
                  updateEvent={(issueIds: Set<number>) => {
                    setStageFilteredIssueIds(issueIds);
                    setIsStageFilterLoading(false);
                  }}
                  filterName="Stage"
                />
              </Box>
              <Box mx={6}>
                <EventFilter
                  key={"AssigneeFilter" + resetKey}
                  isLoading={isAssigneeFilterLoading}
                  events={issues}
                  eventIdSetsFromOtherFilters={issueIdSetsFromOtherFilters(assigneeFilteredIssueIds)}
                  getFilterKey={(issue: IIssue) => issue.assignee ? issue.assignee.name : "Unassigned"}
                  getEventId={(issue: IIssue) => issue.id}
                  convertFilterKeyToLabel={(filterKey: string) => filterKey}
                  updateEvent={(issueIds: Set<number>) => {
                    setAssigneeFilteredIssueIds(issueIds);
                    setIsAssigneeFilterLoading(false);
                  }}
                  filterName="Assigned To"
                />
              </Box>
              <Button
                variant={"ghost"}
                onClick={() => {
                  setFilterLoading();
                  setSearchParams('');
                  rerenderFilters();
                }}
              >
                Clear all
              </Button>
            </Flex>
          </Flex>
          <Flex
            alignItems="flex-start"
            flexDirection="column"
          >
            <Text fontSize={14} mr={4}>Sort by: </Text>
            <Select
              width={60}
              size={"sm"}
              py={1}
              defaultValue={sorting}
              borderRadius={4}
              iconSize={14}
              focusBorderColor="primary.500"
              onChange={(event) => setSorting(event.target.value)}
            >
              {Object.keys(sortingOptions).map((key) => (
                <option key={key} value={key}>{sortingOptions[key].label}</option>
              ))}
            </Select>
          </Flex>
        </Flex>
        <Flex
          my={6}
          flex={1}
          flexDirection="column"
          overflowY="hidden"
        >
          <Flex className={styles.tableHeaderContainer}>
            <Table variant="simple" colorScheme="gray">
              <Thead position="sticky" top={0} bg="white" width="100%">
                <Tr>
                  <Th width="15%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={6} >Building</Th>
                  <Th width="15%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Issue</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Created By</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Assigned To</Th>
                  <Th width="10%" minWidth={140} textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Stage</Th>
                  <Th width="10%" textColor={"gray.500"} textTransform={"none"} fontWeight={"normal"} fontSize={12} pl={0} >Due Date</Th>
                </Tr>
              </Thead>
            </Table>
          </Flex>
          <Flex
            overflowY="scroll"
          >
            <Table variant="simple" colorScheme="gray">
              <Tbody>
                {(filteredIssues || []).map((issue: IIssue) => (
                  <Tr width="100%"
                    key={issue.id}
                    onClick={() => onRowClick(issue.id)} style={{ cursor: 'pointer' }}
                    _hover={{ bg: 'gray.50' }}
                  >
                    <Td width="15%" pl={6} >{issue.location.name}</Td>
                    <Td width="15%" pl={0}><Text noOfLines={2} wordBreak="break-all">{issue.title}</Text></Td>
                    <Td width="10%" pl={0} >{issue.creator?.name}</Td>
                    <Td width="10%" pl={0} >{issue.assignee?.name}</Td>
                    <Td width="10%" minWidth={140} pl={0} ><IssueTag text={StageTagMappings[issue?.currentStage].label} textColor={StageTagMappings[issue?.currentStage].textColor} backgroundColor={StageTagMappings[issue?.currentStage].backgroundColor} /></Td>
                    <Td width="10%" pl={0} >{renderDueDate(issue)}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
      </Box>

      <IssueEditModal isOpen={editIssueModalOpen} issue={null} locations={locations} usersByLocationId={suggestedUsersByLocationId} allUsers={users} close={() => setEditIssueModalOpen(false)} onSave={createIssue} />
    </Flex>
  );
};

export default IssueDashboard;
