import { BiBuildings, BiCalendar, BiEnvelope, BiHomeAlt, BiLogOut, BiTimeFive, BiClipboard, BiCalendarCheck, BiError, BiUser, BiHourglass } from 'react-icons/bi';
import { HiOutlineDocumentReport } from 'react-icons/hi'
import { Button, Flex, Grid, GridItem, Image, Text, VStack } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { CgCalculator } from 'react-icons/cg';
import { FaBroom } from 'react-icons/fa';
import { MdOutlineSpaceDashboard } from 'react-icons/md'
import styles from "./sidebar.module.scss";
import { useAuth } from "../../contexts/auth_context";

const NavButton = ({ text, icon, onClick, isActive }) => {
  return (
    <Button
      size='md'
      height='32px'
      width='230px'
      fontWeight='normal'
      color={isActive ? "primary.500" : "secondary.500"}
      _hover={{ color: "primary.500" }}
      isActive={isActive}
      variant='ghost'
      justifyContent="flex-start"
      onClick={onClick}
      leftIcon={icon}
    >
      {text}
    </Button>)
}

const UrlNavButton = ({ text, icon, url }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const isActivePage = (pagePath: string) => {
    if(pagePath === '/') {
      return location.pathname === '/';
    }
    return location.pathname.includes(pagePath);
  };

  return  <NavButton
    text={text}
    icon={icon}
    onClick={() => {navigate(url)}}
    isActive={isActivePage(url)}
  />
}

// TODO 2023-03 - move the grid layout into a separate component
const Sidebar = () => {
  const authContext = useAuth();

  const VersionInfo = () => {
    return (
      <Flex flexDirection="column" ml={10} fontSize={10} color={"gray.500"}>
        <Text>
          {authContext?.state?.userToken?.organization?.name}
        </Text>
        <Text>
          Version {__COMMIT_HASH__}
        </Text>
      </Flex>
    )
  };

  const DemoFeatures = () => {

    if (!!authContext.state.userToken && authContext.state.userToken.user_info.username.includes("brightgo.com")) {
      return (
        <>
          <UrlNavButton text={"Executive Summary"} icon={<MdOutlineSpaceDashboard/>} url={"/executive_dashboard"} />
          <UrlNavButton text={"Operations Breakdown"} icon={<BiBuildings/>} url={"/operations_dashboard"} />
          <UrlNavButton text={"Accounting"} icon={<CgCalculator/>} url={"/accounting_dashboard"} />
          <UrlNavButton text={"Tag / Ancillary"} icon={<FaBroom/>} url={"/tag_work_dashboard"} />
        </>
      );
    }

    return null;
  }

  return (
    <>
      <Grid
        templateAreas={`"nav main"`}
        gridTemplateRows={'100vh'}
        gridTemplateColumns={'280px 1fr'}
        backgroundColor='secondary.lightGray'
      >
        <GridItem bg='white' area={'nav'} boxShadow='1px 0px 1px rgba(0, 0, 0, 0.05)'>
          <Flex className={styles.sidebar} direction={"column"}>
            <VStack
              spacing={4}
              align='stretch'
            >
               <Image
                width="100px"
                src={import.meta.env.BASE_URL + "image_65-removebg-preview.png"}
                mb={6}
                ml={4}
                mt={4}
              />
              <UrlNavButton text={"Home"} icon={<BiHomeAlt/>} url={"/"} />
              {authContext.state?.features?.operationsBi && (
                <UrlNavButton text={"Buildings"} icon={<BiBuildings />} url={"/buildings"} />
              )}
              {authContext.state?.features?.messaging && (
                <UrlNavButton text={"Messaging"} icon={<BiEnvelope />} url={"/messaging"} />
              )}
              {authContext.state?.features?.inspection && (
                <UrlNavButton text={"Inspection"} icon={<BiClipboard />} url={"/inspection"} />
              )}
              <UrlNavButton text={"Schedules"} icon={<BiCalendar />} url={"/schedules"} />
              <UrlNavButton text={"Clock-In Review"} icon={<BiTimeFive />} url={"/clock_in_dashboard"} />
              {authContext.state?.features?.attendanceTask && (
                <UrlNavButton text={"Task History"} icon={<BiCalendarCheck />} url={"/task_history"} />
              )}
              {authContext.state?.features?.issueTrackingSupervisorView && (
                <UrlNavButton text="Issue Tracking" icon={<BiError />} url={"/issues?Stage=Assigned%20to%20Field|Fixed%20in%20Field|Open"} />
              )}
              {authContext.state?.features?.userManagement && (
                <UrlNavButton text="Users" icon={<BiUser />} url={"/users"} />
              )}
              {authContext.state?.features?.reportsExport && (
                <UrlNavButton text="Reports" icon={<HiOutlineDocumentReport />} url={"/reports"} />
              )}
              {authContext.state?.features?.realTimeLaborEnabled && (
                <UrlNavButton text="Cleaner Hours" icon={<BiHourglass />} url={"/hours"} />
              )}
            </VStack>

            <div className={styles.signOutButton}>
              <NavButton
                text={"Sign Out"}
                icon={<BiLogOut/>}
                onClick={() => {
                  authContext.signOut(authContext.state, authContext.dispatch);
                }}
                isActive={false} // Sign Out button is never active
              />
            </div>

            <VersionInfo />

          </Flex>
        </GridItem>
        <GridItem area={'main'} className={styles.example}>
          <div className={styles.mainContainerWrapper}>
            <Outlet />
          </div>
        </GridItem>
      </Grid>
    </>
  )
};

export default Sidebar;
