import { useAuth } from "@contexts/auth_context";
import { Box, useTheme, Flex, Text, Spinner } from "@chakra-ui/react";
import { ResponsiveBar } from '@nivo/bar';
import useSWR from 'swr';
import { ErrorView } from "@/components/ErrorView";
import { useTimezone } from "@/contexts/timezone_context";

const BudgetActualChart= () => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();
  const theme = useTheme();

  const { data, error } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/real_time_labor/budget_actual/daily_breakdown?period=LAST_TWO_WEEKS`,
    async (url) => {
      const response = await authContext.authenticatedFetch(url);
      return response.json();
    }
  );

  const isLoading = !data && !error;
  const dailyBudgetActual = data?.dailyBreakdown.map((item: any) => ({
    date: timezoneContext.formatDate(timezoneContext.parse(item.date), 'MM/dd'),
    budgetHours: item.budgetHours,
    actualHours: item.actualHours,
    overBudget: Math.max(item.actualHours - item.budgetHours, 0),
    underBudget: Math.max(item.budgetHours - item.actualHours, 0)
  })) ?? [];

  const BarTooltip = ({ id, value, color, indexValue, data }: any) => (
    <Box bg="white" p={2} border="1px solid" borderColor="gray.200" borderRadius="md">
      <Text fontSize="sm" color="gray.500">{`Date: ${indexValue}`}</Text>
      <Text>{`Budget Hours: ${data.budgetHours.toFixed(2)}`}</Text>
      <Text>{`Actual Hours: ${data.actualHours.toFixed(2)}`}</Text>
    </Box>
  );

  if (error) return <ErrorView message="Failed to load budget/actual data" />;
  if (isLoading) return <Flex justifyContent="center" alignItems="center" height="100%"><Spinner size="xl" color="purple.500" /></Flex>;

  return (
    <ResponsiveBar
      data={dailyBudgetActual.map(item => ({
        ...item,
      }))}
      keys={['budgetHours', 'overBudget', 'underBudget']}
      indexBy="date"
      margin={{ top: 50, right: 50, bottom: 50, left: 60 }}
      padding={0.52}
      valueScale={{ type: 'linear' }}
      indexScale={{ type: 'band', round: true }}
      colors={({ id, data }) => {
        if (id === 'budgetHours') return theme.colors.primary[500];
        if (id === 'overBudget') return theme.colors.secondary.red;
        return theme.colors.secondary.gray;
      }}
      defs={[
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.1)',
          rotation: -60,
          lineWidth: 8,
          spacing: 20
        }
      ]}
      fill={[
        { match: { id: 'budgetHours' }, id: 'lines' },
        { match: { id: 'overBudget' }, id: 'lines' },
        { match: { id: 'underBudget' }, id: 'lines' }
      ]}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legend: '',
        legendPosition: 'middle',
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 0,
        tickPadding: 5,
        tickRotation: 0,
        legendPosition: 'middle',
        legendOffset: -40,
      }}
      theme={{
        axis: {
          ticks: {
            text: {
              fill: theme.colors.secondary.darkGray
            }
          }
        }
      }}
      enableGridX={false}
      enableGridY={false}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      tooltip={BarTooltip}
      layers={['bars', 'axes', ({ innerWidth, innerHeight }) => (
        <g>
          <line x1={0} y1={innerHeight} x2={innerWidth} y2={innerHeight} stroke={theme.colors.gray[300]} />
          <line x1={0} y1={0} x2={0} y2={innerHeight} stroke={theme.colors.gray[300]} />
        </g>
      )]}
    />
  );
};

export default BudgetActualChart;
