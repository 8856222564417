import { Flex, Divider, Text } from "@chakra-ui/react";
import { RealTimeLaborTable } from "@/components/RealTimeLaborTable";
import { BudgetActualChart } from "@/components/BudgetActualChart";
import { BudgetActualCard } from "@/components/BudgetActualCard";
import { OvertimeCard } from "@/components/OvertimeCard";
import styles from './styles.module.scss';

const RealTimeLaborDashboard: React.FC = () => {

  return (
    <Flex direction="column" align="center" w="100%">
      <Flex
        w="100%"
        justifyContent="space-between"
      >
        <Flex direction="column" w="50%" mt={6}>
          <Text
            className={styles.cardHeader}
            mx={6}
          >
            Budget v.s.Actual
          </Text>
          <Flex className={`${styles.card} ${styles.metricCard}`}>
            <BudgetActualCard period="LAST_DAY" />
          </Flex>
          <Flex className={`${styles.card} ${styles.metricCard}`}>
            <BudgetActualCard period="THIS_WEEK" />
          </Flex>
        </Flex>
        <Divider orientation="vertical" height="auto" borderColor="gray.200" my={6} alignSelf="stretch" />
        <Flex direction="column" w="50%" mt={6}>
        <Text
            className={styles.cardHeader}
            mx={6}
          >
            Overtime
          </Text>
          <Flex className={`${styles.card} ${styles.metricCard}`}>
            <OvertimeCard period="LAST_DAY" />
          </Flex>
          <Flex className={`${styles.card} ${styles.metricCard}`}>
            <OvertimeCard period="THIS_WEEK" />
          </Flex>
        </Flex>
      </Flex>

      <Text
        className={styles.cardHeader}
        alignSelf="flex-start"
        mx={6}
      >
        Budget v.s. Actual by Day
      </Text>
      <Flex
        className={styles.card}
        h="400px"
        width="calc(100% - 3rem)"
      >
        <BudgetActualChart />
      </Flex>

      <Text
        className={styles.cardHeader}
        alignSelf="flex-start"
        mx={6}
      >
        Budget and Overtime Per Supervisor
      </Text>
      <Flex
        className={`${styles.card}`}
        flex={1}
        overflowY="hidden"
        display="flex"
        minHeight="200px"
        width="calc(100% - 3rem)"
      >
        <RealTimeLaborTable />
      </Flex>
    </Flex>
  );
};

export default RealTimeLaborDashboard;
