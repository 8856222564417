import { createContext, useContext, ReactNode } from "react";
import { useAuth } from "./auth_context";
import { formatInTimeZone, toZonedTime as dateFnsToZonedTime, fromZonedTime as dateFnsFromZonedTime, toDate } from 'date-fns-tz';
import { format, startOfWeek as dateFnsStartOfWeek, isSameDay as dateFnsIsSameDay, parseISO as dateFnsParseISO, startOfDay as dateFnsStartOfDay } from 'date-fns';
import { StartOfWeekOptions } from 'date-fns';

interface TimezoneContextType {
  parse: (date: string) => Date;
  formatDate: (date: Date, formatString: string) => string;
  startOfWeek: (date: Date, options: StartOfWeekOptions) => Date;
  startOfDay: (date: Date) => Date;
  isSameDay: (date1: Date, date2: Date) => boolean;
  getDay: (date: Date) => number;
  toZonedTime: (date: Date) => Date;
  fromZonedTime: (date: Date) => Date;
}

const TimezoneContext = createContext<TimezoneContextType | undefined>(undefined);

const TimezoneProvider = ({ children }: { children: ReactNode;}) => {
  const authContext = useAuth();
  const userTimezone = authContext?.state?.timezone || "America/Los_Angeles";
  const overrideLocalTimezone = authContext?.state?.features?.developerFeature;

  const toZonedTime = (date: Date): Date => {
    if (!date) {
      return date;
    }

    if (overrideLocalTimezone) {
      return dateFnsToZonedTime(date, userTimezone);
    } else {
      return date;
    }
  };

  const fromZonedTime = (date: Date): Date => {
    if (!date) {
      return date;
    }

    if (overrideLocalTimezone) {
      return dateFnsFromZonedTime(date, userTimezone);
    } else {
      return date;
    }
  };

  const parse = (date: string): Date => {
    if (overrideLocalTimezone) {
      return toDate(date, { timeZone: userTimezone });
    } else {
      return dateFnsParseISO(date);
    }
  };

  const formatDate = (date: Date, formatString: string): string => {
    if (!date) {
      return "";
    }
    if (overrideLocalTimezone) {
      return formatInTimeZone(date, userTimezone, formatString);
    } else {
      return format(date, formatString);
    }
  };

  const startOfWeek = (date: Date, options: StartOfWeekOptions): Date => {
    if (overrideLocalTimezone) {
      const inputZoned = toZonedTime(date);
      const fnZoned = dateFnsStartOfWeek(inputZoned, options);
      return fromZonedTime(fnZoned);
    } else {
      return dateFnsStartOfWeek(date, options);
    }
  };

  const startOfDay = (date: Date): Date => {
    if (overrideLocalTimezone) {
      const inputZoned = toZonedTime(date);
      const fnZoned = dateFnsStartOfDay(inputZoned);
      return fromZonedTime(fnZoned);
    } else {
      return dateFnsStartOfDay(date);
    }
  };

  const isSameDay = (date1: Date, date2: Date): boolean => {
    if (overrideLocalTimezone) {
      const inputZoned1 = toZonedTime(date1);
      const inputZoned2 = toZonedTime(date2);
      return dateFnsIsSameDay(inputZoned1, inputZoned2);
    } else {
      return dateFnsIsSameDay(date1, date2);
    }
  };

  const getDay = (date: Date): number => {
    if (overrideLocalTimezone) {
      return toZonedTime(date).getDay();
    } else {
      return date.getDay();
    }
  };

  const value = {
    toZonedTime,
    fromZonedTime,
    parse,
    formatDate,
    startOfWeek,
    startOfDay,
    isSameDay,
    getDay,
  };

  return (
    <TimezoneContext.Provider value={value}>
      {children}
    </TimezoneContext.Provider>
  );
};

const useTimezone = () => {
  const context = useContext(TimezoneContext);
  if (context === undefined) {
    throw new Error('useTimezone must be used within a TimezoneProvider');
  }
  return context;
};

export { TimezoneProvider, useTimezone };
