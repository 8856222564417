import { useAuth } from "@contexts/auth_context";
import { Flex, Text, Spinner } from "@chakra-ui/react";
import { useTimezone } from "@contexts/timezone_context";
import useSWR from 'swr';
import { ErrorView } from "@/components/ErrorView";

const BudgetActualCard = ({ period }: { period: 'LAST_DAY' | 'THIS_WEEK' }) => {
  const authContext = useAuth();
  const timezoneContext = useTimezone();

  const { data, error } = useSWR(
    `${import.meta.env.VITE_API_SERVER}/mobile/v1/real_time_labor/budget_actual/daily_breakdown?period=${period}`,
    async (url) => {
      const response = await authContext.authenticatedFetch(url);
      return response.json();
    }
  );

  const isLoading = !data && !error;
  const totalBudgetHours = data?.totalBudgetHours ?? null;
  const totalActualHours = data?.totalActualHours ?? null;
  const startDate = data?.startDate ? timezoneContext.formatDate(timezoneContext.parse(data.startDate), 'MMM dd, yyyy') : null;
  const endDate = data?.endDate ? timezoneContext.formatDate(timezoneContext.parse(data.endDate), 'MMM dd, yyyy') : null;

  if (error) return <ErrorView message="Failed to load budget/actual data" />;
  if (isLoading) return <Flex justifyContent="center" alignItems="center" height="100%"><Spinner size="xl" color="purple.500" /></Flex>;

  return (
    <Flex direction="column" align="center" w="100%">
      <Text>Total Budget Hours: {totalBudgetHours}</Text>
      <Text>Total Actual Hours: {totalActualHours}</Text>
      <Text fontSize="sm" color="gray.500" mt={2}>
        {startDate && endDate ? `${startDate} - ${endDate}` : ''}
      </Text>
    </Flex>
  );
};

export default BudgetActualCard;
